"use client";
import cn from "@/utils/cn";
import TextComponent from "../TextComponent";
import Link from "next/link";
import { useZStore } from "@/utils/z-store";

type CopyrightTextProps = React.HTMLAttributes<HTMLDivElement>;

function CopyrightText({ className, ...props }: CopyrightTextProps) {
  const { actions } = useZStore();
  return (
    <div
      className={cn(
        // "mb-2 text-[9px] md:text-[10.80px] font-normal text-[#96A5B8] xl:text-[13px] leading-[16.2px] md:leading-[18px] xl:leading-[28px]",
        "flex justify-between w-full md:flex-row flex-col-reverse text-center",
        className
      )}
      {...props}
    >
      <div
        onClick={() => {
          actions.openModal({ modalName: "manage-consent" });
        }}
        className="absolute bottom-0 left-10 flex h-[18px] w-[79px] cursor-pointer items-center justify-center rounded-t-[9px] bg-black/10 text-[#05004E] md:left-5 md:h-6 md:w-[105px] xl:left-20 xl:h-10 xl:w-[138px] xl:rounded-t-xl"
      >
        <TextComponent type="caption">Manage Consent</TextComponent>
      </div>
      <TextComponent type="caption" className=" mt-2.5 text-[#96A5B8]">
        Email Audit Engine © 2025 All Rights Reserved
      </TextComponent>
      <TextComponent
        type="caption"
        className="cursor-pointer text-[#05004E] space-x-2"
      >
        <Link
          className="hover:text-kd-lte-blue"
          href="https://emailauditengine.com/blog/privacy-policy"
        >
          Privacy Policy
        </Link>
        <span>|</span>
        <Link
          className="hover:text-kd-lte-blue"
          href="https://emailauditengine.com/blog/terms-and-conditions"
        >
          Terms and Conditions
        </Link>
      </TextComponent>
    </div>
  );
}

export default CopyrightText;
