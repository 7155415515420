"use client";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import Button from "@/components/buttons";
import cn from "@/utils/cn";
import { RichTextField } from "@prismicio/client";
import { PrismicRichText } from "@prismicio/react";
import { useZStore } from "@/utils/z-store";
import { CTABackground } from "@/assets/svg-icons/svg-icons";
import SignupInputField from "./SignupInputField";
import React, { useState, useEffect } from "react";
import TextComponent from "./TextComponent";

// Define a single schema for both fields
const formSchema = z.object({
  email: z
    .string()
    .email("Please enter a valid email address.")
    .min(1, "Email is required."),
  apiKey: z
    .string()
    .min(1, "API Key is required.")
    .regex(
      /^[a-f0-9]{32}-us[0-9]{1,2}$/i,
      "Please enter a valid Mailchimp API key.",
    ),
});

type FormData = z.infer<typeof formSchema>;

type CTABannerProps = {
  text: RichTextField;
} & React.HTMLAttributes<HTMLDivElement>;

export default function CTABanner({
  text,
  className,
  ...props
}: CTABannerProps) {
  const { actions } = useZStore();
  const [isLoading, setIsLoading] = useState(false);
  // Updated status state to match Footer's implementation
  const [submissionStatus, setSubmissionStatus] = useState<{
    type: "success" | "error" | null;
    message: string | null;
  }>({ type: null, message: null });
  const [showApiKey, setShowApiKey] = useState(false);

  // Add useEffect for auto-hiding success message
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (submissionStatus.type === "success") {
      timeoutId = setTimeout(() => {
        setSubmissionStatus({ type: null, message: null });
      }, 10000); // Hide after 10 seconds
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [submissionStatus.type]);

  // Initialize react-hook-form with complete schema
  const methods = useForm<FormData>({
    resolver: zodResolver(formSchema),
    mode: "onChange",
  });

  const {
    handleSubmit,
    trigger,
    formState: { errors },
    watch,
  } = methods;

  // Watch email field to validate before showing API key field
  const email = watch("email");

  const handleContinue = async () => {
    const isEmailValid = await trigger("email");
    if (isEmailValid) {
      // Reset the API key value before showing the field
      methods.setValue("apiKey", "");
      setShowApiKey(true);
    }
  };

  const onSubmit = async (data: FormData) => {
    console.log("🚀 ~ onSubmit ~ data:", data);
    setIsLoading(true);
    setSubmissionStatus({ type: null, message: null });

    try {
      const response = await fetch("/api/forms/submit-and-fetch", {
        method: "POST",
        body: JSON.stringify({
          ...data,
          mailchimpKey: data.apiKey,
          formId: "EAE_FORM", // Added static formId
          sourceUrl: window.location.href, // Added current URL
        }),
      });

      if (!response.ok) {
        throw new Error("Submission failed");
      }

      setSubmissionStatus({
        type: "success",
        message:
          "Thank you! We will process your request and send you the report shortly.",
      });

      methods.reset();
      setShowApiKey(false);
    } catch (error: any) {
      setSubmissionStatus({
        type: "error",
        message: error.message || "An error occurred. Please try again.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className={cn(
        "bg-kd-lte-blue bg-gradient-to-b md:py-[45px] px-8 md:px-[30.5px] xl:px-[96px] space-y-10 md:space-y-0 flex md:flex-row flex-col md:justify-between md:items-center py-8 xl:pt-[68px] xl:pb-[56px] w-full rounded-[15px] relative overflow-hidden",
        className,
      )}
      id="audit-report-form"
      {...props}
    >
      <CTABackground className="absolute -top-0 left-0 z-0 h-full" />

      <div className=""></div>
      {showApiKey && (
        <TextComponent
          type="body"
          className="z-10 text-center text-[#D1D1D6] md:w-[33%] xl:ml-20"
        >
          Leverage your existing data and add a{" "}
          <span className="font-medium text-white">Mailchimp API Key</span> to
          generate a report.
        </TextComponent>
      )}

      {!showApiKey && (
        <p className="z-10 w-[90%] text-[27px] font-semibold leading-9 text-kd-white md:w-3/4 xl:text-[38px] xl:leading-[54px]">
          Get a Free Audit
        </p>
      )}

      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          // className="relative z-10 flex items-center space-x-6 space-y-4 md:flex-row md:space-y-0"
        >
          <div
            // className="flex items-center"
            className="relative z-10 flex items-center space-x-6 space-y-4 md:flex-row md:space-y-0"
          >
            {submissionStatus?.message && (
              <TextComponent
                type="caption"
                className={cn(
                  "absolute lg:-top-6 -top-4 left-5",
                  submissionStatus.type === "success"
                    ? "text-[#00E096]"
                    : "text-[#FA5A7D]",
                )}
              >
                {submissionStatus.message}
              </TextComponent>
            )}

            {!showApiKey ? (
              <>
                <SignupInputField
                  name="email"
                  type="email"
                  placeholder="Email Address"
                  required
                  className="w-full border-[#EDF2F680] bg-[#5B89FE] text-[#FFFFFF] placeholder:text-white "
                  // xl:w-[350px] md:w-[270px]
                  containerClassName="w-full md:w-[347px]"
                />

                <Button
                  type="button"
                  variant="primary"
                  onClick={handleContinue}
                  disabled={isLoading || !email}
                  className="h-[30px] text-nowrap   bg-white capitalize text-kd-lte-blue xl:h-[40px]"
                >
                  Continue
                </Button>
              </>
            ) : (
              <>
                <SignupInputField
                  name="apiKey"
                  type="text"
                  placeholder="Enter your Mailchimp API key"
                  required
                  className="w-full border-[#EDF2F680] bg-[#5B89FE] text-[#FFFFFF] placeholder:text-white"
                  containerClassName="w-full md:w-[347px]"
                />

                <Button
                  id="audit-report"
                  type="submit"
                  variant="primary"
                  disabled={isLoading}
                  className="h-[30px] text-nowrap bg-white capitalize text-kd-lte-blue xl:h-[40px]"
                >
                  {isLoading ? "Sending..." : "Send Report"}
                </Button>
              </>
            )}
          </div>
        </form>
      </FormProvider>
      {/* {formStatus.message && (
        <TextComponent
          type="caption"
          className={cn("z-10  -top-0", {
            "text-red-500": formStatus.type === "error",
            "text-green-500": formStatus.type === "success",
          })}
        >
          {formStatus.message}
        </TextComponent>
      )} */}
    </div>
  );
}
