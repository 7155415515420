import { signOut } from "next-auth/react";
import Link from "next/link";
import Image from "next/image";
import { useEffect, useRef, useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import cn from "@/utils/cn";
import { useZStore } from "@/utils/z-store";
import UserProfileIcon from "../icons/user-profile-icon-blue.svg";
import TextComponent from "@/app/_components/TextComponent";
import { BsThreeDotsVertical } from "react-icons/bs";

const UserProfileSidebar = ({
  userName,
  userRole,
  userEmail,
  profilePicture,
  userImage,
}: {
  userName: string;
  userRole: string | undefined;
  userEmail: string;
  profilePicture?: string | null;
  userImage?: string | null;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const { actions } = useZStore();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const menuItems = [
    { label: "Profile", href: "/dashboard/settings/profile" },
    { label: "Account", href: "/dashboard/settings/account" },
    { label: "Billing", href: "/dashboard/settings/billing" },
    { label: "Users", href: "/dashboard/settings/users" },
    { label: "Integration", href: "/dashboard/settings/integrations" },
    { label: "Tools", href: "/dashboard/settings/tools" },
    { label: "Logout", href: "#" },
  ];

  return (
    <div className="relative" ref={sidebarRef}>
      {/* Trigger Button */}
      <div
        className="flex cursor-pointer items-center justify-center space-x-4"
        onClick={() => setIsOpen(!isOpen)}
      >
        {/* Three Dots for Mobile */}
        <div className="md:hidden">
          <BsThreeDotsVertical className="size-6 text-kd-lte-blue" />
        </div>

        {/* Profile Picture and Name (Hidden on Mobile) */}
        <div className="hidden md:block">
          <div className="size-[36px] overflow-hidden rounded-full xl:size-[48px]">
            {profilePicture || userImage ? (
              // eslint-disable-next-line @next/next/no-img-element
              <img
                src={profilePicture || userImage || UserProfileIcon}
                alt="Profile"
                className="size-full object-cover"
              />
            ) : (
              <Image
                src={UserProfileIcon}
                alt="Default User Icon"
                className="size-full object-cover"
              />
            )}
          </div>
        </div>
        <div className="hidden flex-col md:flex">
          <div className="flex items-center capitalize text-[#05004E]">
            <TextComponent type="h4">
              {userName?.split(" ")[0] || "User"}
            </TextComponent>
            {isOpen ? (
              <IoIosArrowUp className="ml-2 size-3 xl:size-6" />
            ) : (
              <IoIosArrowDown className="ml-2 size-3 xl:size-6" />
            )}
          </div>
        </div>
      </div>
      {/* Sidebar Content */}
      {isOpen && (
        <div
          className={cn(
            "absolute select-none right-0 top-16 z-50 h-fit rounded-[16px] border border-kd-lte-blue bg-white shadow-lg transition-transform duration-300 ease-in-out",
            "xl:w-[280px] w-[210px]",
          )}
        >
          <div>
            <div className="flex items-center p-4">
              <div className="flex size-8 items-center justify-center rounded-full text-white xl:size-12">
                <Image alt="" src={UserProfileIcon} />
              </div>
              <div className="ml-4 flex flex-col space-y-0.5 font-medium xl:space-y-1">
                <div className="flex items-center space-x-2 capitalize">
                  <TextComponent type="h4" className="font-semibold">
                    {userName}
                  </TextComponent>
                  <TextComponent
                    type="caption"
                    className="rounded bg-gray-200 px-1 py-0.5 text-gray-600 xl:px-2 xl:py-1"
                  >
                    {userRole}
                  </TextComponent>
                </div>
                <TextComponent type="body" className="text-[#96A5B8]">
                  {userEmail}
                </TextComponent>
              </div>
            </div>

            <nav>
              <ul className="divide-y">
                {menuItems.map((item, index) => (
                  <li
                    key={index}
                    className="capitalize hover:bg-[#EDF2F6] hover:text-kd-lte-blue"
                  >
                    {item.label === "Logout" ? (
                      <button
                        onClick={async () => {
                          try {
                            await signOut({ callbackUrl: "/" });
                            localStorage.clear();
                            actions.resetStore();
                          } catch (error) {
                            console.error("Logout error:", error);
                          }
                        }}
                        className="block w-full rounded px-5 py-3 text-left text-red-500 xl:px-6 xl:py-[18px]"
                      >
                        <TextComponent type="body">{item.label}</TextComponent>
                      </button>
                    ) : (
                      <Link
                        href={item.href}
                        className="block h-full rounded px-5 py-3 xl:px-6 xl:py-[18px]"
                      >
                        <TextComponent type="body">{item.label}</TextComponent>
                      </Link>
                    )}
                  </li>
                ))}
              </ul>
            </nav>

            <TextComponent
              type="caption"
              className=" flex flex-col px-[26px] pb-4 pt-2 text-[#96A5B8] "
            >
              <div className="flex space-x-1">
                <Link
                  href="https://emailauditengine.com/blog/privacy-policy"
                  className="block hover:text-gray-700"
                >
                  <u>Privacy</u>
                </Link>
                <span> and </span>
                <Link
                  href="https://emailauditengine.com/blog/terms-and-conditions"
                  className="block hover:text-gray-700"
                >
                  <u>Terms</u>
                </Link>
              </div>
              <Link href="#" className="block hover:text-gray-700">
                Cookie Preferences
              </Link>
            </TextComponent>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserProfileSidebar;
